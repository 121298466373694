import React from 'react'
import { green } from '@material-ui/core/colors';
import * as Liff from '../../apis/liff';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';

export default () => {

  React.useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 4000);
  }, [])

  const onClose = () => {
    if (Liff.isInClient()) {
      Liff.closeWindow();
    } else {
      window.location.href = 'me.moronline://exit';
    }
  }

  return (
    <Layout bg="bg4" title="">
      <Box p={2}>
        <Typography variant="h5" component="h1" color="textPrimary">
          นัดปรึกษาแพทย์เรียบร้อย
        </Typography>
        <Box mt={3} textAlign="center">
          <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 50 }} />
        </Box>
        <Box mt={3}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={onClose}
          >
            ปิดหน้านี้
          </Button>
        </Box>
      </Box>
    </Layout >
  );
}
